import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

//Get components for routing
import { HomeComponent } from "./home/home.component";
import { AboutComponent } from "./about/about.component";
import { ServiceComponent} from "./service/service.component";
import { ContactComponent } from "./contact/contact.component";

const routes: Routes = [
    {
        path:'home',
        component: HomeComponent
    },
    {
        path:'services',
        component: ServiceComponent
    },
    {
        path:'about',
        component:AboutComponent
    },
    {
        path:'contact',
        component:ContactComponent
    },
    {
        path:'**',
        redirectTo:'/home',
        pathMatch:'full'
    }
]

@NgModule({
    imports:[RouterModule.forRoot(routes)],
    exports:[RouterModule]
})
export class AppRoutingModule{};