import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-service',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.css']
})
export class ServiceComponent implements OnInit {

  activeTab = 'IT';

  constructor() { }

  ngOnInit() {
  }

  switchTab(type){

    if(type == 'logistics' || type == 'towing'){
      this.activeTab = type
    }
    else{
      this.activeTab = 'IT'
    }
  }

}
